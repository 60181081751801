import { createContext } from 'react';
import { Instance, types, getSnapshot, IAnyModelType, onAction } from 'mobx-state-tree';
import { authType, authState, query, subscribe, unSubscribe, signUp, signUpAsOrganization, signIn, signOut, answerOtpChallenge, CloudStore, authStateType, authTypeType } from '../../ui/AWS';
import { internalActions, modelActions, stream, ministry, user, userType } from '../../kue-api-code-gen/api-types';
import * as queries from '../../kue-api-code-gen/queries';
import * as mutations from '../../kue-api-code-gen/mutations';
import * as subscriptions from '../../kue-api-code-gen/subscriptions';

const graphqlQuery = types.enumeration('graphqlQueries', Object.keys(queries));

export type graphqlQueryType = Instance<typeof graphqlQuery>;

export const image = types.model('image', {
    id: types.identifier,
    url: types.string
});

export type imageType = Instance<typeof image>;

const color = types.model('color', {
    red: types.number,
    green: types.number,
    blue: types.number,
    alpha: types.optional(types.number, 1)
});

export type colorType = Instance<typeof color>;

const section = types.model('section', {
    name: types.string,
    configuration: types.optional(types.string, '{}')
});

export type sectionType = Instance<typeof section>;

const subscription = types.model('subscription', {
    id: types.identifier,
    data: types.optional(types.string, '{}')
});

export type subscriptionType = Instance<typeof subscription>;

const queryModel = types.model('query', {
    id: types.identifier,
    data: types.string,
    expiration: types.optional(types.integer, new Date().getTime() + 60000)
});

export type queryType = Instance<typeof queryModel>;

const page = types
    .model('page', {
        name: types.string,
        path: types.maybe(types.string),
        includeInNavigation: types.optional(types.boolean, true),
        includeInFooter: types.optional(types.boolean, false),
        navLogo: types.maybe(image),
        protected: types.maybe(authType),
        sections: types.optional(types.array(section), []),
        subRoutes: types.optional(types.array(types.late((): IAnyModelType => page)), []),
        backgroundColor: types.maybe(color),
        container: types.optional(types.boolean, false),
        isIndex: types.optional(types.boolean, false),
        query: types.maybe(graphqlQuery),
        queryVariable: types.maybe(types.string),
        loading: types.optional(types.boolean, false),
        error: types.maybe(types.string),
        editorType: types.maybe(types.string),
    });

export type pageType = Instance<typeof page>;

const siteConfigStore = types
    .model('siteConfigStore', {
        title: types.string,
        logo: types.maybe(image),
        favicon: types.maybe(image),
        brandColor: types.optional(color, { red: 40, green: 44, blue: 51 }),
        backgroundColor: types.optional(color, { red: 255, green: 255, blue: 255 }),
        primaryColor: types.optional(color, { red: 40, green: 44, blue: 51 }),
        secondaryColor: types.optional(color, { red: 40, green: 44, blue: 51 }),
        terciaryColor: types.optional(color, { red: 40, green: 44, blue: 51 }),
        whiteColor: types.optional(color, { red: 255, green: 255, blue: 255 }),
        blackColor: types.optional(color, { red: 0, green: 0, blue: 0 }),
        alertColor: types.optional(color, { red: 255, green: 0, blue: 0 }),
        images: types.map(image),
        contentWidth: types.optional(types.number, .95),
        pages: types.optional(types.array(page), []),
        navType: types.optional(types.string, 'DefaultNav'),
        backgroundType: types.maybe(types.string),
        footerType: types.optional(types.string, 'DefaultFooter'),
        saturated: types.optional(types.boolean, true),
        // subscriptions: types.map(subscription),
        signInPath: types.optional(types.string, '/'),
        authState: types.optional(authState, 'NONE'),
        user: types.maybe(user),
        queries: types.map(queryModel),
        ministryMap: types.map(ministry),
        streamMap: types.map(stream),
        playerUrl: types.optional(types.string, 'https://63050ee307b58b8f.mediapackage.us-east-1.amazonaws.com/out/v1/eb7270f563784ee6a8f7460fbe3aecbb/main/index.m3u8'),
    })
    .views(self => ({
        colorAsRgb(colorToConvert?: colorType) {
            if (!colorToConvert) {
                return (undefined)
            } else {
                //@ts-ignore
                return (`rgb(${colorToConvert.red}, ${colorToConvert.green}, ${colorToConvert.blue})`);
            };
        },
        colorAsRgba(colorToConvert?: colorType, alphaOverride?: number) {
            if (!colorToConvert) {
                return (undefined)
            } else {
                //@ts-ignore
                return (`rgba(${colorToConvert.red}, ${colorToConvert.green}, ${colorToConvert.blue}, ${alphaOverride || colorToConvert.alpha})`);
            }
        },
        currentUser() {
            if (self.user?.id) {
                return self.user.id
            } else {
                return CloudStore().userId!
            }
        }
    }))
    .actions(internalActions)
    .actions(self => ({
        //INTERNAL UTILITY ACTIONS
        _saturateWebsiteSuccess(data: any) {
            const newConfiguration = JSON.parse(data.GetApplicationConfiguration.configuration);
            // Object.assign(self, newConfiguration);
            console.log('ASSIGNED NEW CONFIGURATION: ', newConfiguration, self);
            // document.title = newConfiguration.title;

            self.saturated = true;
        },
        _saturateWebsiteError(error: any) {
            self.saturated = false;
            console.log('SATURATE WEBSITE ERROR: ', error)
        },
        _setUser(user: userType) {
            self.user = user;
        },
        _handleSubscriptionMessage(dataString: string) {
            console.log(dataString);
            const data = JSON.parse(dataString);
            if (data.GetUpdates) {
                console.log('get updates called');
            } else if (data.SubscribeToStream) {
                const stream = self.streamMap.get(data.SubscribeToStream.id);
                if (stream) {
                    const newStream = { ...stream };
                    Object.keys(data.SubscribeToStream).forEach(key => {
                        if (data.SubscribeToStream[key]) {
                            //@ts-expect-error
                            newStream[key] = data.SubscribeToStream[key];
                        }
                    });
                    self.streamMap.put(newStream);
                };
            } else {
                console.log('unrecognized update');
            };
        }
    }))
    //SITE ACTIONS
    .actions(self => ({
        saturateWebsite() {
            //todo: add the tags for the site
            //todo: check if config is in the bucket, then do the query
            // query({
            //     query: queries.getApplicationConfiguration,
            //     variables: {
            //         configType: 'WEBSITE'
            //     },
            //     authMode: 'AWS_IAM'
            // })
            //     .then(
            //         self._saturateWebsiteSuccess
            //     )
            //     .catch(
            //         self._saturateWebsiteError
            //     );
        },
        publishSite() {
            //todo: change auth to cognito
            const snapShot = getSnapshot(self);
            console.log(snapShot);
            const configuration = JSON.stringify(snapShot);
            console.log(configuration);
            query({
                query: mutations.updateApplicationConfiguration,
                variables: {
                    configuration: configuration,
                    configType: 'WEBSITE'
                },
                authMode: 'AWS_IAM'
            })
                .then(data => console.log(data))
                .catch(error => console.log(error));
        },
        mutate(queryParams: { query: string, variables: { [key: string]: any }, authMode?: authTypeType }) {
            return (
                new Promise<{ [key: string]: any }>((resolve, reject) => {
                    query({
                        //@ts-ignore
                        query: mutations[queryParams.query],
                        variables: queryParams.variables,
                        authMode: self.authState === 'SIGNED_IN' ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM'
                    })
                        .then(resolve)
                        .catch(reject)
                })
            );
        },
        query(queryParams: { query: string, variables: { [key: string]: any }, authMode?: authTypeType }) {
            return (
                new Promise<{ [key: string]: any }>((resolve, reject) => {
                    query({
                        //@ts-ignore
                        query: queries[queryParams.query],
                        variables: queryParams.variables,
                        authMode: queryParams.authMode ? queryParams.authMode : self.authState === 'SIGNED_IN' ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM'
                    })
                        .then(resolve)
                        .catch(reject)
                })
            );
        },
        subscribe(queryParams: { query: string, variables: { [key: string]: any }, authMode?: authTypeType }) {
            return (
                new Promise<{ [key: string]: any }>((resolve, reject) => {
                    subscribe({
                        // @ts-ignore
                        query: subscriptions[queryParams.query],
                        variables: queryParams.variables,
                        authMode: self.authState === 'SIGNED_IN' ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM',
                    })
                        .then(resolve)
                        .catch(reject)
                })
            );
        },
        unSubscribe(id: string) {
            unSubscribe({
                authMode: self.authState === 'SIGNED_IN' ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM',
                id: id,
            })
        },
        upload(uploadParams: { url: string, file: BodyInit, progressHandler?: (progress: { totalSize: number, amountComplete: number, percentComplete: number }) => any }) {
            return (
                new Promise((resolve, reject) => {
                    const request = new XMLHttpRequest();
                    request.open('PUT', uploadParams.url);
                    if (uploadParams.progressHandler) {
                        request.upload.onprogress = event => {
                            uploadParams.progressHandler!(
                                {
                                    totalSize: event.total,
                                    amountComplete: event.loaded,
                                    percentComplete: (event.loaded / event.total) * 100
                                }
                            );
                        };
                    };
                    request.onload = resolve;
                    request.onerror = reject;
                    request.send(uploadParams.file);
                })
            );
        },
        setPlayerUrl(url: string) {
            self.playerUrl = url;
        },
    }))
    //AUTHENTICATION ACTIONS
    .actions(self => ({
        signUp(signUpParams: { email: string }) {
            self.authState = 'SIGNING_UP';
            return (
                new Promise((resolve, reject) => {
                    signUp(signUpParams)
                        .then(resolve)
                        .catch(reject)
                })
            );
        },
        signUpAsOrganization(signUpParams: { description: string, name: string, contactCity: string, contactEmail: string, contactPhone: string, contactState: string, contactStreetAddress: string, contactZipCode: string }) {
            signUpAsOrganization(signUpParams)
        },
        signIn(signInParams: { email: string }) {
            self.authState = 'SIGNING_IN';
            return (
                new Promise((resolve, reject) => {
                    signIn(signInParams)
                        .then(resolve)
                        .catch(reject)
                })
            );
        },
        answerOtpChallenge(answerOtpChallengeParams: { otp: string }) {
            self.authState = 'ANSWERING_KUE_OTP_CHALLENGE';
            return (
                new Promise((resolve, reject) => {
                    answerOtpChallenge(answerOtpChallengeParams)
                        .then(data => {
                            console.log(data);
                            self.query({
                                //@ts-ignore
                                query: 'getUser',
                                variables: {
                                    id: data.userId,
                                    includeGivenName: true,
                                },
                                authMode: 'AMAZON_COGNITO_USER_POOLS'
                            })
                                .then(data => {
                                    console.log(data);
                                    self._addUser(data.GetUser)
                                });
                            resolve(data);
                        })
                        .catch(reject)
                })
            );
        },
        signOut() {
            self.authState = 'SIGNING_OUT';
            return (
                new Promise((resolve, reject) => {
                    signOut()
                        .then(resolve)
                        .catch(reject)
                })
            );
        },
        updateAuthState(authState: authStateType) {
            self.authState = authState;
        },
    }))
    .actions(modelActions);

export type siteConfigType = Instance<typeof siteConfigStore>;

//@ts-ignore
const faithCastConfig: siteConfigType = {
    title: 'The FaithCast',
    logo: {
        id: 'faithcastLogo',
        url: 'https://kue-app-assets.s3.amazonaws.com/kue/universal-assets/fclogo.png'
    },
    favicon: {
        id: 'favicon',
        url: 'https://kue-app-assets.s3.amazonaws.com/kue/universal-assets/fc-favicon.ico'
    },
    navType: 'FcNav',
    // backgroundType: 'FcBackgroundComponent',
    brandColor: {
        red: 52,
        green: 68,
        blue: 209,
        alpha: 1
    },
    backgroundColor: {
        red: 44,
        green: 40,
        blue: 47,
        alpha: 1
    },
    primaryColor: {
        red: 161,
        green: 22,
        blue: 231,
        alpha: 1
    },
    secondaryColor: {
        red: 115,
        green: 25,
        blue: 254,
        alpha: 1
    },
    terciaryColor: {
        red: 230,
        green: 25,
        blue: 152,
        alpha: 1
    },
    blackColor: {
        red: 44,
        green: 40,
        blue: 47,
        alpha: 1
    },
    whiteColor: {
        red: 254,
        green: 251,
        blue: 255,
        alpha: 1
    },
    alertColor: {
        red: 238,
        green: 27,
        blue: 57,
        alpha: 1
    },
    images: {
        //@ts-ignore
        default: {
            id: 'default',
            // url: 'https://images.unsplash.com/photo-1507692049790-de58290a4334?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80',
            // url: 'https://images.unsplash.com/photo-1563089145-599997674d42?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80',
            // url: 'https://images.unsplash.com/photo-1547583073-4b4fe00854c0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80',
            // url: 'https://images.unsplash.com/photo-1627055823360-a1696736b029?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1539&q=80',
            // url: 'https://kuesamplevideos.s3.amazonaws.com/tcabWithFace.001.jpeg',
            url: 'https://kuesamplevideos.s3.amazonaws.com/tcabWithLogo.001.jpeg',
            // url: 'https://images.unsplash.com/photo-1477672680933-0287a151330e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80',

            // url: 'https://images.unsplash.com/photo-1543525238-54e3d131f7ca?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80',
            // url: 'https://www.joelosteen.com/-/media/Images/Jom/Why%20Give/2019%20Giving-Posterframe.jpg?h=1105&la=en-US&w=1800'
        },
        defaultBadge: {
            id: 'defaultBadge',
            // url: 'https://images.unsplash.com/photo-1507692049790-de58290a4334?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80',
            // url: 'https://images.unsplash.com/photo-1563089145-599997674d42?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80',
            // url: 'https://images.unsplash.com/photo-1547583073-4b4fe00854c0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80',
            // url: 'https://images.unsplash.com/photo-1627055823360-a1696736b029?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1539&q=80',
            url: 'https://kuesamplevideos.s3.amazonaws.com/tcabWithFace.001.jpeg',
            // url: 'https://kuesamplevideos.s3.amazonaws.com/tcabWithLogo.001.jpeg',
            // url: 'https://images.unsplash.com/photo-1477672680933-0287a151330e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80',

            // url: 'https://images.unsplash.com/photo-1543525238-54e3d131f7ca?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80',
            // url: 'https://www.joelosteen.com/-/media/Images/Jom/Why%20Give/2019%20Giving-Posterframe.jpg?h=1105&la=en-US&w=1800'
        },
        defaultBadgeCompact: {
            id: 'defaultBadge',
            // url: 'https://images.unsplash.com/photo-1507692049790-de58290a4334?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80',
            url: 'https://images.unsplash.com/photo-1563089145-599997674d42?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80',
            // url: 'https://images.unsplash.com/photo-1547583073-4b4fe00854c0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80',
            // url: 'https://images.unsplash.com/photo-1627055823360-a1696736b029?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1539&q=80',
            // url: 'https://kuesamplevideos.s3.amazonaws.com/tcabWithFace.001.jpeg',
            // url: 'https://kuesamplevideos.s3.amazonaws.com/tcabWithLogo.001.jpeg',
            // url: 'https://images.unsplash.com/photo-1477672680933-0287a151330e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80',

            // url: 'https://images.unsplash.com/photo-1543525238-54e3d131f7ca?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80',
            // url: 'https://www.joelosteen.com/-/media/Images/Jom/Why%20Give/2019%20Giving-Posterframe.jpg?h=1105&la=en-US&w=1800'
        }
    },
    contentWidth: .92,
    signInPath: 'user',
    pages: [
        {
            name: 'The FaithCast',
            path: '/*',
            includeInNavigation: false,
            //@ts-ignore
            sections: [
                // {
                //     name: 'FaithCastTitleBar',
                //     configuration: JSON.stringify({
                //         title: `HIS Mercy Endurs Forever`,
                //         buttons: ['call', 'email']
                //     })
                // },
                {
                    name: 'FcHome',
                    configuration: JSON.stringify({})
                },
            ]
        },
        // {
        //     name: 'Watch Live',
        //     path: 'live',
        //     includeInFooter: false,
        //     includeInNavigation: true,
        //     //@ts-ignore
        //     sections: [
        //         {
        //             name: 'PageDevInfo',
        //             configuration: JSON.stringify({ propColor: 'green' })
        //         },
        //     ]
        // },
        {
            name: 'Ministries',
            path: 'ministries',
            //@ts-ignore
            sections: [
                {
                    name: 'FcGallery',
                    configuration: JSON.stringify({
                        type: 'ministry'
                    })
                },
            ]
        },
        // {
        //     name: 'Ministry Tools',
        //     path: 'services',
        //     includeInFooter: false,
        //     includeInNavigation: true,
        //     //@ts-ignore
        //     sections: [
        //         {
        //             name: 'PageDevInfo',
        //             configuration: JSON.stringify({ propColor: 'pink' })
        //         },
        //     ]
        // },
        {
            name: 'User',
            path: 'user',
            includeInFooter: false,
            includeInNavigation: false,
            //@ts-ignore
            sections: [
                {
                    name: 'FcUser',
                    configuration: JSON.stringify({ propColor: 'yellow' })
                }
            ]
        },
        // {
        //     name: 'Contact',
        //     path: 'contact',
        //     includeInFooter: true,
        //     includeInNavigation: false,
        //     //@ts-ignore
        //     sections: [
        //         {
        //             name: 'PageDevInfo',
        //             configuration: JSON.stringify({ propColor: 'blue' })
        //         },
        //     ]
        // },
        {
            name: 'Ministry',
            path: 'ministries/:ministryId',
            includeInNavigation: false,
            //@ts-ignore
            sections: [
                {
                    name: 'FcMinistry',
                    configuration: JSON.stringify({
                        heroType: 'video',
                        buttons: ['giving', 'email', 'call', 'visit'],
                        dataPath: ['pageData', 'GetMinistry'],
                        suppressScrolling: true,
                    })
                },
            ],
            //@ts-ignore
            subRoutes: [
                {
                    name: 'Ministry',
                    path: '',
                    sections: [
                        {
                            name: 'MinistryInfo',
                            configuration: JSON.stringify({
                                dataPath: ['parentData'],
                            })
                        },
                        {
                            name: 'FcGallery',
                            configuration: JSON.stringify({
                                type: 'stream',
                                dataPath: ['parentData', 'streams'],
                            })
                        }
                    ]
                },
                {
                    name: 'Videos',
                    path: 'media',
                    sections: [
                        {
                            name: 'MinistryInfo',
                            configuration: JSON.stringify({
                                dataPath: ['parentData'],
                            })
                        },
                        {
                            name: 'FcGallery',
                            configuration: JSON.stringify({
                                type: 'stream',
                                dataPath: ['parentData', 'streams'],
                            })
                        }
                    ]
                },
                {
                    name: 'Stream',
                    path: 'media/streams/:streamId',
                    includeInNavigation: false,
                    sections: [
                        {
                            name: 'MinistryInfo',
                            configuration: JSON.stringify({
                                dataPath: ['parentData'],
                            })
                        },
                        {
                            name: 'FcGallery',
                            configuration: JSON.stringify({
                                type: 'stream',
                                dataPath: ['parentData', 'streams'],
                            })
                        }
                    ]
                },
            ]
        },
        {
            name: 'Ministry Admin',
            path: 'ministries/:ministryId/admin',
            includeInNavigation: false,
            protected: 'AMAZON_COGNITO_USER_POOLS',
            //@ts-ignore
            sections: [
                {
                    name: 'FcMinistryAdmin',
                    configuration: JSON.stringify({
                    })
                },
            ],
            //@ts-ignore
            subRoutes: [
                {
                    name: 'Statistics',
                    path: '',
                    sections: [
                        {
                            name: 'ContentStatistics',
                            configuration: JSON.stringify({})
                        }
                    ]
                },
                {
                    name: 'Ministry Settings',
                    path: 'settings',
                    sections: [
                        {
                            name: 'FcMinistrySettings',
                            configuration: JSON.stringify({
                                dataPath: ['parentData'],
                            })
                        },
                    ]
                },
                {
                    name: 'Media',
                    path: 'media',
                    sections: [
                        {
                            name: 'FcMinistryMedia',
                            configuration: JSON.stringify({
                                dataPath: ['parentData']
                            })
                        },
                        {
                            name: 'FcGallery',
                            configuration: JSON.stringify({
                                type: 'adminStream',
                                dataPath: ['parentData', 'streams'],
                            })
                        }
                    ]
                },
                {
                    name: 'Stream',
                    path: 'media/streams/:streamId',
                    includeInNavigation: false,
                    sections: [
                        {
                            name: 'FcMinistryStream',
                            configuration: JSON.stringify({})
                        },
                    ]
                },
                // {
                //     name: 'Meetings',
                //     path: 'meetings',
                //     sections: [
                //         {
                //             name: 'PageDevInfo',
                //             configuration: JSON.stringify({ propColor: 'pink' })
                //         },
                //     ]
                // },
                // {
                //     name: 'Announcements',
                //     path: 'announcements',
                //     sections: [
                //         {
                //             name: 'PageDevInfo',
                //             configuration: JSON.stringify({ propColor: 'black' })
                //         },
                //     ]
                // },
            ]
        },
        {
            name: 'Ministry Invitation',
            path: 'ministries/:ministryId/invitations/:invitationId',
            includeInNavigation: false,
            protected: 'AMAZON_COGNITO_USER_POOLS',
            //@ts-ignore
            sections: [
                {
                    name: 'ContentStatistics',
                    configuration: JSON.stringify({})
                }
            ]
        }
    ],
};

let _siteConfigStore: siteConfigType;

export const SiteConfigStore = () => {
    if (!_siteConfigStore) {
        //@ts-ignore
        _siteConfigStore = siteConfigStore.create(faithCastConfig || {
            title: '',
        });
        // console.log('new site config store');

        _siteConfigStore.updateAuthState(CloudStore().authState);
    };
    return (_siteConfigStore);
};

export const siteContext = createContext(SiteConfigStore());

onAction(CloudStore(), call => {
    if (call.name === 'changeAuthState') {
        SiteConfigStore().updateAuthState(call.args![0]);
        console.log('authStateChanged', call);
    } else if (call.name === 'addSubscriptionData') {
        SiteConfigStore()._handleSubscriptionMessage(call.args![1]);
    }
});