//@ts-nocheck

import React from "react";
import { View, Text } from "react-native";

export default class ErrorBoundary extends React.Component {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false, error: null };
    }

    componentDidCatch(error: any, errorInfo: any) {
        this.setState({
            error,
            errorInfo
        });
        console.error('COMPONENT DID CATCH: ', error, errorInfo);
    }

    render() {
        //@ts-ignore
        if (this.state.error) {
            return (
                <View style={{ width: '100vw', backgroundColor: 'red' }} >
                    <Text
                        style={{ color: 'white', fontSize: 30 }}
                    >
                        {this.state.error.toString()}
                    </Text>
                </View>
            );
        } else {
            return (this.props.children);
        };
    }
}