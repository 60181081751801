import { Suspense, lazy, useContext, useState, useEffect } from 'react';
import { View, useWindowDimensions } from 'react-native';
import { observer } from 'mobx-react-lite';
import { useOutletContext, useNavigate, useLocation } from '../ui/Router';
import { siteContext, pageType } from './stores/siteConfig';
import ErrorBoundary from './ErrorBoundary';
import { CloudStore } from '../ui/AWS';

interface pageProps {
    page: pageType
};

export default observer((props: pageProps) => {

    const [sections, setSections] = useState([{ component: undefined, props: {} }]);

    const [pageData, setPageData] = useState<{ [key: string]: any } | undefined>();

    const siteConfig = useContext(siteContext);

    const parentData = useOutletContext();

    const navigate = useNavigate();

    const location = useLocation();

    useEffect(() => {
        CloudStore().addAnalyticsEvent({
            action: 'pageView',
            resourceType: 'webapp',
            path: location.pathname,
        });
    }, [location.pathname]);

    useEffect(() => {
        if ((pageData?.name && pageData.name !== 'Error') || pageData?.title) {
            document.title = `${props.page.name} - ${pageData.name || pageData.title}`
        } else {
            document.title = `${siteConfig.title} - ${props.page.name}`;
        };
    }, [props.page, pageData, siteConfig.title]);

    useEffect(() => {
        const sectionList: { component: any, props: {} }[] = props.page.sections.map(section => {
            return ({
                component: lazy(() => import(`./sections/${section.name}`)),
                props: section,
            })
        });
        setSections(sectionList)
    }, [props.page]);

    if (props.page.protected && siteConfig.authState !== 'SIGNED_IN') {
        navigate(`/${siteConfig.signInPath}`);
        return null
    } else {
        return (
            <View
                style={{
                    backgroundColor: siteConfig.colorAsRgb(props.page.backgroundColor) || 'transparent',
                    // minHeight: '-webkit-fill-available',
                    flex: 1,
                    width: '100%'
                }}
            >
                {sections[0].component ?
                    sections.map((section, index) =>
                        <ErrorBoundary key={index}>
                            <Suspense fallback={<View />} >
                                {/* @ts-ignore */}
                                <section.component
                                    section={section.props}
                                    page={props.page}
                                    site={siteConfig}
                                    setPageData={setPageData}
                                    parentData={parentData}
                                />
                            </Suspense>
                        </ErrorBoundary>
                    )
                    : null}
            </View>
        );
    };
});
