import { createContext } from 'react';
import { Instance, types } from 'mobx-state-tree';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import { HttpRequest } from '@aws-sdk/protocol-http';
import { SignatureV4 } from '@aws-sdk/signature-v4';
// import { NodeHttpHandler } from '@aws-sdk/node-http-handler';
import { FetchHttpHandler, streamCollector } from '@aws-sdk/fetch-http-handler';
import { Sha256 } from '@aws-crypto/sha256-js';
import { toUtf8 } from '@aws-sdk/util-utf8-browser';
// import { toBase64 } from '@aws-sdk/util-base64-browser';
import { v4 } from 'uuid';

//cloudstore is the truth local and session storages are backups

export const authState = types.enumeration('authState', ['SIGNING_IN', 'SIGNED_IN', 'SIGNING_UP', 'SIGNED_UP', 'SIGNED_OUT', 'SIGNING_OUT', 'KUE_OTP_CHALLENGE', 'ANSWERING_KUE_OTP_CHALLENGE', 'SIGN_IN_FAILED', 'SIGN_UP_FAILED', 'NONE']);

export type authStateType = Instance<typeof authState>;

export const authType = types.enumeration('AuthTypes', ['API_KEY', 'AWS_IAM', 'AMAZON_COGNITO_USER_POOLS', 'OPENID_CONNECT']);

export type authTypeType = Instance<typeof authType>;

export const awsConfiguration = types.model('awsConfiguration', {
    region: types.string,
    userPoolId: types.string,
    userPoolClientId: types.string,
    identityPoolId: types.string,
    appsyncRegion: types.string,
    appsyncGraphqlEndpoint: types.maybe(types.string),
    appsyncAuthenticationType: authType
});

export type awsConfigurationType = Instance<typeof awsConfiguration>;

export const subscriptionStatus = types.enumeration(['STARTING', 'SUBSCRIBED', 'UNSUBSCRIBED', 'ERROR']);

export type subscriptionStatusType = Instance<typeof subscriptionStatus>;

export const subscription = types.model('subscription', {
    id: types.identifier,
    status: subscriptionStatus,
    error: types.maybe(types.string),
    data: types.array(types.string)
});

export type subscriptionType = Instance<typeof subscription>;

export const webSocketStatus = types.enumeration(['READY', 'STARTING', 'CLOSED', 'NO_STATUS']);

export type webSocketStatusType = Instance<typeof webSocketStatus>;

export const analyticsEvent = types.model('analyticsEvent', {
    id: types.identifier,
    time: types.number,
    event: types.string
});

export type analyticsEventType = Instance<typeof analyticsEvent>;

const sendAnalyticsEventsMutation = `mutation AddAnalyticsEvents($events:[AnalyticsEventInput]!) {
    AddAnalyticsEvents(events: $events)
}`;

const cloudStore = types
    .model('cloudStore', {
        userId: types.maybe(types.string),
        awsConfiguration: awsConfiguration,
        authSession: types.maybe(types.string),
        authState: types.optional(authState, 'NONE'),
        tokenExpirationTime: types.maybe(types.integer),
        refreshToken: types.maybe(types.string),
        identityToken: types.maybe(types.string),
        accessToken: types.maybe(types.string),
        deviceKey: types.maybe(types.string),
        deviceGroupKey: types.maybe(types.string),
        subscriptions: types.map(subscription),
        webSocketStatus: types.optional(webSocketStatus, 'NO_STATUS'),
        analyticsEvents: types.array(types.maybe(analyticsEvent))
    })
    .actions(self => ({
        changeAuthState(authState: authStateType) {
            self.authState = authState;
        },
        setUserId(userId: string) {
            self.userId = userId;
        },
        updateAuthSession(authSession: string) {
            self.authSession = authSession;
            console.log('auth session change', self);
        },
        setAccessToken(accessToken: string) {
            self.accessToken = accessToken;
        },
        setIdentityToken(identityToken: string) {
            self.identityToken = identityToken;
        },
        setRefreshToken(refreshToken: string) {
            self.refreshToken = refreshToken;
            sessionStorage.setItem('refreshToken', refreshToken);
        },
        setTokenExpirationTime(secondsTillExpiration: string) {
            const tokenExpirationTime = new Date().getTime() + (1000 * parseInt(secondsTillExpiration));
            self.tokenExpirationTime = tokenExpirationTime;
            console.log('seconds till expiration: ', secondsTillExpiration);
            setTimeout(() => refreshTokens(), 1000 * (parseInt(secondsTillExpiration) - 10));
        },
        setDeviceKey(deviceKey: string) {
            self.deviceKey = deviceKey;
        },
        setDeviceGroupKey(deviceGroupKey: string) {
            self.deviceGroupKey = deviceGroupKey;
        },
        clearTokensAndUserId() {
            self.tokenExpirationTime = undefined;
            self.refreshToken = undefined;
            sessionStorage.removeItem('refreshToken');
            localStorage.removeItem('refreshToken');
            sessionStorage.removeItem('userId');
            localStorage.removeItem('userId');
            self.identityToken = undefined;
            self.accessToken = undefined;
            self.userId = undefined;
        },
        setWebSocketStatus(status: webSocketStatusType) {
            self.webSocketStatus = status;
        },
        createSubscription(id: string) {
            self.subscriptions.put({ id: id, status: 'STARTING' });
        },
        startSubscription(id: string) {
            const subscription = self.subscriptions.get(id);
            if (subscription) {
                subscription.status = 'SUBSCRIBED'
            };
        },
        errorSubscription(id: string, error: string) {
            const subscription = self.subscriptions.get(id);
            if (subscription) {
                subscription.error = error;
                subscription.status = 'ERROR'
            };
        },
        addSubscriptionData(id: string, data: string) {
            const subscription = self.subscriptions.get(id);
            if (subscription) {
                subscription.data.unshift(data);
            };
        },
        stopSubscription(id: string) {
            const subscription = self.subscriptions.get(id);
            if (subscription) {
                subscription.status = 'UNSUBSCRIBED';
            };
        },
        removeSubscription(id: string) {
            self.subscriptions.delete(id);
        },
        addAnalyticsEvent(event: { action: string, resourceType: string, [key: string]: any }) {
            self.analyticsEvents.push({
                id: v4(),
                time: Date.now(),
                event: JSON.stringify(event)
            });
        },
        clearAnaliticsEvents() {
            self.analyticsEvents.length = 0;
        },
    }))
    .actions(self => ({
        sendAnalyticsEvents() {
            if (self.analyticsEvents.length > 0) {
                console.log('sendanalyticsevents');
                query({
                    query: sendAnalyticsEventsMutation,
                    variables: { events: self.analyticsEvents },
                    authMode: self.authState === 'SIGNED_IN' ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM'
                }).then(data => {
                    console.log(data);
                    self.clearAnaliticsEvents();
                }).catch(console.log).finally(console.log);
            } else { console.log('nothing to send') }
        }
    }));

export type cloudStoreType = Instance<typeof cloudStore>;

let _cloudStore: cloudStoreType;

export const CloudStore = () => {
    if (!_cloudStore) {
        _cloudStore = cloudStore.create({
            authState: sessionStorage.getItem('refreshToken') ? 'SIGNED_IN' : 'NONE',
            refreshToken: sessionStorage.getItem('refreshToken') || undefined,
            userId: sessionStorage.getItem('userId') || undefined,
            awsConfiguration: {
                //figure something else out for native
                region: process.env.REACT_APP_REGION!,
                userPoolId: process.env.REACT_APP_USER_POOL_ID!,
                userPoolClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID!,
                identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID!,
                appsyncRegion: process.env.REACT_APP_REGION!,
                appsyncGraphqlEndpoint: process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT!,
                appsyncAuthenticationType: 'AMAZON_COGNITO_USER_POOLS'
            },
            analyticsEvents: [{
                id: v4(),
                time: Date.now(),
                event: JSON.stringify({
                    action: 'APP_START',
                    resourceType: 'webapp',
                    domain: window.location.hostname,
                    path: window.location.pathname
                })
            }]
        });
        setInterval(() => _cloudStore.sendAnalyticsEvents(), 300000);
        // document.onvisibilitychange = () => console.log(document.visibilityState);
    };
    return _cloudStore
};

export const cloudContext = createContext(CloudStore());



export interface queryParams {
    query: string,
    variables: {},
    authMode: awsConfigurationType["appsyncAuthenticationType"]
}

const createHttpRequest = (createHttpRequestParams: { uri: URL, queryParams?: queryParams, path?: string, body?: string }) => {

    // console.log('CREATEHTTPREQUEST PARAMS', createHttpRequestParams);

    const request = new HttpRequest({
        body: JSON.stringify(createHttpRequestParams.queryParams || {}),
        headers: {
            host: createHttpRequestParams.uri.host,
            accept: 'application/json, text/javascript',
            'content-encoding': 'amz-1.0',
            'Content-Type': 'application/json; charset=UTF-8',
        },
        hostname: createHttpRequestParams.uri.hostname,
        method: 'POST',
        path: `${createHttpRequestParams.uri.pathname}${createHttpRequestParams.path || ''}`,
    });

    // console.log('HTTP REQUEST:', request);

    return (request);
};

var credential: any;

const getIamCredential = () => {
    if (credential) {
        console.log('old credential');
        return (credential)
    } else {
        console.log('new credential');
        const newCredential = () => fromCognitoIdentityPool({
            identityPoolId: _cloudStore.awsConfiguration.identityPoolId,
            clientConfig: {
                region: _cloudStore.awsConfiguration.region
            }
        });
        credential = newCredential();
        return (credential());
    }
};

const signRequest = (request: HttpRequest) => {
    const signer = new SignatureV4({
        applyChecksum: true,
        service: 'appsync',
        region: _cloudStore.awsConfiguration.region,
        credentials: getIamCredential(),
        sha256: Sha256,
    });

    // console.log('AWS V4 SIGNATURE', signer);

    return (signer.sign(request));
};

const fetchData = (request: HttpRequest) => {
    // console.log('API REQUEST', request);
    const client = new FetchHttpHandler();
    return (new Promise<{ [key: string]: any }>((resolve, reject) => {
        client.handle(request)
            .then(httpResponse =>
                streamCollector(httpResponse.response.body)
            )
            .then(collection => {
                const text = toUtf8(collection);
                // console.log(text);
                const response = JSON.parse(text);
                if (response.errors) {
                    reject(response.errors[0].message)
                } else {
                    // console.log(response.data);
                    resolve(response.data)
                }
            })
            .catch(error => {
                console.log('FETCH DATA ERROR', error);
            })
    }));
};

export const query = async (queryParams: queryParams) => {

    return (new Promise<{ [key: string]: any }>((resolve, reject) => {
        // console.log('QUERY PARAMETERS:', queryParams);
        // console.log('CLOUD STORE & REGION:', _cloudStore, _cloudStore.awsConfiguration.region);

        const uri = new window.URL(_cloudStore.awsConfiguration.appsyncGraphqlEndpoint!);
        // console.log('GRAPHQL ENDPOINT:', uri);

        const request = createHttpRequest({ uri: uri, queryParams: queryParams });
        // console.log('AUTH MODE', queryParams.authMode);
        if (queryParams.authMode === 'AWS_IAM') {
            // console.log('USING IAM');
            signRequest(request)
                //@ts-ignore
                .then(fetchData)
                .then(resolve)
                .catch(error => {
                    console.log(error);
                    reject(error);
                })
        } else if (queryParams.authMode === 'AMAZON_COGNITO_USER_POOLS') {
            if ((_cloudStore.tokenExpirationTime! < new Date().getTime()) || !_cloudStore.identityToken) {
                // console.log('query', 'token ex time: ', _cloudStore.tokenExpirationTime, 'now: ', new Date().getTime());
                refreshTokens()
                    .then(() => {
                        if (_cloudStore.identityToken) {
                            request.headers.Authorization = _cloudStore.identityToken;
                        }
                        fetchData(request)
                            .then(resolve)
                            .catch(error => {
                                console.log(error);
                                reject(error);
                            });
                    })
                    .catch(console.log)
            } else if (_cloudStore.identityToken) {
                // console.log('USING EXISTING TOKENS');
                request.headers.Authorization = _cloudStore.identityToken;
                fetchData(request)
                    .then(data => {
                        resolve(data)
                    })
                    .catch(error => {
                        console.log(error);
                        reject(error);
                    });
            };
        };
    }));
};

var webSocket: WebSocket;

var timer: NodeJS.Timeout;

var timeoutInterval: number | undefined;

const addWebsocketListeners = (socket: WebSocket) => {

    socket.addEventListener('close', event => {
        console.log(event);
        _cloudStore.setWebSocketStatus('CLOSED');
        openWebSocket({ authMode: _cloudStore.authState === 'SIGNED_IN' ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM' });
    });
    socket.addEventListener('error', event => {
        console.log(event);
    });
    socket.addEventListener('message', (event: WebSocketMessageEvent) => {
        const message = JSON.parse(event.data);
        // console.log(message);
        if (message.type === 'connection_ack') {
            _cloudStore.setWebSocketStatus('READY');
            timeoutInterval = message.payload.connectionTimeoutMs;
            timer = setTimeout(socket.close, timeoutInterval);
        } else if (message.type === 'ka') {
            clearTimeout(timer);
            timer = setTimeout(webSocket.close, timeoutInterval);
        } else if (message.type === 'start_ack') {
            _cloudStore.startSubscription(message.id)
        } else if (message.type === 'error') {
            _cloudStore.errorSubscription(message.id, JSON.stringify(message.payload.errors));
        } else if (message.type === 'data') {
            _cloudStore.addSubscriptionData(message.id, JSON.stringify(message.payload.data));
        } else if (message.type === 'stop') {
            _cloudStore.stopSubscription(message.id);
        } else if (message.type === 'complete') {
            console.log('Remaining Subscriptions: ', _cloudStore.subscriptions);
            if (_cloudStore.subscriptions.size === 0) {
                console.log('Closing WebSocket');
                webSocket.close();
                _cloudStore.setWebSocketStatus('CLOSED');
            };
        } else {
            console.log('unhandled message: ', message)
        };
    });
    webSocket.addEventListener('open', event => {
        // console.log(event);
        webSocket.send(JSON.stringify({
            type: 'connection_init'
        }))
    });
};

const createWebsocket = (webSocketParams: { headers: HttpRequest['headers'], uri: URL }) => {
    const requestJSON = JSON.stringify(webSocketParams.headers);
    // console.log(requestJSON);

    const realtimeUri = webSocketParams.uri.hostname.split('.')[1] === 'appsync-api' ? new window.URL(`wss://${webSocketParams.uri.hostname.split('.')[0]}.appsync-realtime-api.${_cloudStore.awsConfiguration.region}.amazonaws.com/graphql?header=${btoa(requestJSON,)}&payload=${btoa('{}')}`) : new window.URL(`wss://${webSocketParams.uri.hostname}/graphql/realtime?header=${btoa(requestJSON,)}&payload=${btoa('{}')}`);

    // console.log('REALTIME URL: ', realtimeUri);

    webSocket = new WebSocket(realtimeUri, 'graphql-ws');
    addWebsocketListeners(webSocket);
    return (webSocket);
};

export const openWebSocket = async (subscriptionParams: { authMode: awsConfigurationType["appsyncAuthenticationType"] }): Promise<WebSocket> => {

    return (new Promise((resolve, reject) => {

        // console.log('WEBSOCKET AUTH MODE:', subscriptionParams.authMode);

        // console.log('CLOUD STORE & REGION', _cloudStore, _cloudStore.awsConfiguration.region);

        const uri = new window.URL(_cloudStore.awsConfiguration.appsyncGraphqlEndpoint!);

        // console.log('WEBSOCKET ENDPOINT', uri);

        const request = createHttpRequest({ uri: uri, path: '/connect' });

        if (subscriptionParams.authMode === 'AWS_IAM') {
            signRequest(request)
                .then(signedRequest => {
                    // console.log('SIGNED REQUEST:', signedRequest);
                    resolve(createWebsocket({
                        headers: signedRequest.headers,
                        uri: uri,
                    }));
                })
                .catch(reject)

        } else if (subscriptionParams.authMode === 'AMAZON_COGNITO_USER_POOLS') {
            if (_cloudStore.tokenExpirationTime! < new Date().getTime()) {
                console.log('websockets', 'token ex time: ', _cloudStore.tokenExpirationTime, 'now: ', new Date().getTime());
                refreshTokens()
                    .then(() => {
                        if (_cloudStore.identityToken) {
                            request.headers.Authorization = _cloudStore.identityToken;
                        };
                        resolve(createWebsocket({
                            headers: request.headers,
                            uri: uri,
                        }));
                    })
                    .catch(reject)
            } else if (_cloudStore.identityToken) {
                request.headers.Authorization = _cloudStore.identityToken;
                resolve(createWebsocket({
                    headers: request.headers,
                    uri: uri,
                }));
            };
        };
    }))
};

export const unSubscribe = (subscriptionParams: { id: string, authMode: awsConfigurationType["appsyncAuthenticationType"] }) => {
    console.log('UNSUBSCRIBE AUTH MODE', subscriptionParams.authMode);

    console.log('CLOUD STORE & REGION', _cloudStore, _cloudStore.awsConfiguration.region);

    const uri = new window.URL(_cloudStore.awsConfiguration.appsyncGraphqlEndpoint!);

    console.log('GRAPHQL ENDPOINT', uri);

    const bodyJson = JSON.stringify({
        query: undefined,
        variables: undefined
    });

    const request = createHttpRequest({
        uri: uri,
        body: bodyJson,

    });

    if (subscriptionParams.authMode === 'AWS_IAM') {
        signRequest(request)
            .then(signedRequest => {
                _cloudStore.removeSubscription(subscriptionParams.id);
                webSocket.send(JSON.stringify({
                    id: subscriptionParams.id,
                    type: 'stop',
                    payload: {
                        data: bodyJson,
                        extensions: {
                            authorization: signedRequest.headers
                        }
                    },
                }));
            })
    } else if (subscriptionParams.authMode === 'AMAZON_COGNITO_USER_POOLS') {
        if (_cloudStore.tokenExpirationTime! < new Date().getTime()) {
            console.log('unsubscribe', 'token ex time: ', _cloudStore.tokenExpirationTime, 'now: ', new Date().getTime());
            refreshTokens()
                .then(() => {
                    if (_cloudStore.identityToken) {
                        request.headers.Authorization = _cloudStore.identityToken;
                    };
                    _cloudStore.removeSubscription(subscriptionParams.id);
                    webSocket.send(JSON.stringify({
                        id: subscriptionParams.id,
                        type: 'stop',
                        payload: {
                            data: bodyJson,
                            extensions: {
                                authorization: request.headers
                            }
                        },
                    }));
                })
        } else if (_cloudStore.identityToken) {
            request.headers.Authorization = _cloudStore.identityToken;
            _cloudStore.removeSubscription(subscriptionParams.id);
            webSocket.send(JSON.stringify({
                id: subscriptionParams.id,
                type: 'stop',
                payload: {
                    data: bodyJson,
                    extensions: {
                        authorization: request.headers
                    }
                },
            }));
        };
    };
};

export const subscribe = async (subscriptionParams: { query: string, variables: {}, authMode: awsConfigurationType["appsyncAuthenticationType"] }): Promise<any> => {

    return new Promise((resolve) => {

        if (webSocket && webSocket.readyState === 1 && _cloudStore.webSocketStatus === 'READY') {

            // console.log('SUBSCRIBE AUTH MODE', subscriptionParams.authMode);

            // console.log('CLOUD STORE & REGION', _cloudStore, _cloudStore.awsConfiguration.region);

            const uri = new window.URL(_cloudStore.awsConfiguration.appsyncGraphqlEndpoint!);

            // console.log('GRAPHQL ENDPOINT', uri);

            // console.log('SUBSCRIPTION PARAMS', subscriptionParams);

            const bodyJson = JSON.stringify({
                query: subscriptionParams.query,
                variables: subscriptionParams.variables
            });

            const request = createHttpRequest({
                uri: uri,
                queryParams: JSON.parse(bodyJson)

            });

            if (subscriptionParams.authMode === 'AWS_IAM') {
                signRequest(request)
                    .then(signedRequest => {
                        // console.log('SIGNED REQUEST: ', signedRequest);
                        // console.log('REQUEST: ', request);
                        const subscriptionUuid = v4();
                        _cloudStore.createSubscription(subscriptionUuid);
                        webSocket.send(JSON.stringify({
                            id: subscriptionUuid,
                            type: 'start',
                            payload: {
                                data: bodyJson,
                                extensions: {
                                    authorization: signedRequest.headers
                                }
                            },
                        }));
                        resolve({ id: subscriptionUuid });
                    })
            } else if (subscriptionParams.authMode === 'AMAZON_COGNITO_USER_POOLS') {
                if (_cloudStore.tokenExpirationTime! < new Date().getTime()) {
                    console.log('subscribe', 'token ex time: ', _cloudStore.tokenExpirationTime, 'now: ', new Date().getTime());
                    refreshTokens()
                        .then(() => {
                            if (_cloudStore.identityToken) {
                                request.headers.Authorization = _cloudStore.identityToken;
                            };
                            const subscriptionUuid = v4();
                            _cloudStore.createSubscription(subscriptionUuid);
                            webSocket.send(JSON.stringify({
                                id: subscriptionUuid,
                                type: 'start',
                                payload: {
                                    data: bodyJson,
                                    extensions: {
                                        authorization: request.headers
                                    }
                                },
                            }));
                            resolve({ id: subscriptionUuid });
                        })
                } else if (_cloudStore.identityToken) {
                    request.headers.Authorization = _cloudStore.identityToken;
                    const subscriptionUuid = v4();
                    _cloudStore.createSubscription(subscriptionUuid);
                    webSocket.send(JSON.stringify({
                        id: subscriptionUuid,
                        type: 'start',
                        payload: {
                            data: bodyJson,
                            extensions: {
                                authorization: request.headers
                            }
                        },
                    }));
                    resolve({ id: subscriptionUuid });
                };
            };
        } else if (webSocket && (_cloudStore.webSocketStatus === 'STARTING' || _cloudStore.webSocketStatus === 'NO_STATUS')) {
            console.log('WAITING FOR WEBSOCKET TO START');
            return (
                setTimeout(() => resolve(subscribe(subscriptionParams)), 1000)
            );
        } else {
            openWebSocket({ authMode: subscriptionParams.authMode })
                .then(() => {
                    console.log('OPENING WEBSOCKET', _cloudStore.webSocketStatus);
                    return resolve(subscribe(subscriptionParams))
                })
        }
    });
};

const signUpMutation = `mutation SignUp($email:AWSEmail!) {
    SignUp(email: $email){
        status
    }
}`;

export const signUp = (signUpParams: { email: string }) => {
    _cloudStore.changeAuthState('SIGNING_UP');
    return (
        new Promise((resolve, reject) => {
            query({
                query: signUpMutation,
                variables: { email: signUpParams.email },
                authMode: 'AWS_IAM'
            })
                .then(data => {
                    _cloudStore.changeAuthState('SIGNED_UP');
                    resolve(data);
                    // console.log(data)
                })
                .catch(error => {
                    console.log(error);
                    _cloudStore.changeAuthState('SIGN_UP_FAILED');
                    reject(error);
                })
            //do more stuff if necessary
        })
    );
};

const signUpAsOrganizationMutation = `mutation SignUpAsOrganization($description:String!, $name:String!, $contactCity:String!, $contactEmail:AWSEmail!, $contactPhone:AWSPhone!, $contactState:String!, $contactStreetAddress:String!, $contactZipCode:String!) {
    SignUpAsOrganization(description: $description, name:$name, contactCity:$contactCity, contactEmail:$contactEmail, contactPhone:$contactPhone, contactState:$contactState, contactStreetAddress:$contactStreetAddress,contactZipCode:$contactZipCode){
        status
    }
}`;

export const signUpAsOrganization = (signUpParams: { description: string, name: string, contactCity: string, contactEmail: string, contactPhone: string, contactState: string, contactStreetAddress: string, contactZipCode: string }) => {
    _cloudStore.changeAuthState('SIGNING_UP');
    return (
        query({
            query: signUpAsOrganizationMutation,
            variables: signUpParams,
            authMode: 'AWS_IAM'
        })
            .then(data => {
                _cloudStore.changeAuthState('SIGNED_UP');
                console.log(data)
            })
            .catch(error => {
                console.log(error);
                _cloudStore.changeAuthState('SIGN_UP_FAILED');
            })
        //do more stuff if necessary
    );
};

const signInMutation = `mutation SignIn($email:AWSEmail!, $clientId:String!) {
    SignIn(email: $email, clientId:$clientId){
        challengeName
        id
        session
    }
}`;

export const signIn = (signInParams: { email: string }) => {
    _cloudStore.changeAuthState('SIGNING_IN');
    return (
        new Promise((resolve, reject) => {
            query({
                query: signInMutation,
                variables: {
                    email: signInParams.email,
                    clientId: _cloudStore.awsConfiguration.userPoolClientId
                },
                authMode: 'AWS_IAM'
            })
                .then(data => {
                    console.log(data);
                    _cloudStore.setUserId(data.SignIn.id);
                    _cloudStore.updateAuthSession(data.SignIn.session)
                    if (data.SignIn.challengeName === 'CUSTOM_CHALLENGE') {
                        _cloudStore.changeAuthState('KUE_OTP_CHALLENGE');
                    };
                    resolve(data)
                })
                .catch(error => {
                    console.log(error);
                    _cloudStore.changeAuthState('SIGN_IN_FAILED');
                    reject(error);
                })
        })
    );
};

const answerOtpChallengeMutation = `mutation AnserOtpChallenge($otp:String!, $clientId:String!, $session:String!, $userId:String!) {
    AnswerOtpChallenge(otp: $otp, clientId:$clientId, session:$session, userId:$userId){
        accessToken
        expiresIn
        idToken
        refreshToken
        tokenType
    }
}`;

export const answerOtpChallenge = (answerOtpChallengeParams: { otp: string }) => {
    _cloudStore.changeAuthState('ANSWERING_KUE_OTP_CHALLENGE');
    return (
        new Promise<{ userId: string }>((resolve, reject) => {
            query({
                query: answerOtpChallengeMutation,
                variables: {
                    otp: answerOtpChallengeParams.otp,
                    userId: _cloudStore.userId,
                    clientId: _cloudStore.awsConfiguration.userPoolClientId,
                    session: _cloudStore.authSession
                },
                authMode: 'AWS_IAM'
            })
                .then(data => {
                    console.log(data);
                    _cloudStore.setAccessToken(data.AnswerOtpChallenge.accessToken);
                    _cloudStore.setIdentityToken(data.AnswerOtpChallenge.idToken);
                    _cloudStore.setRefreshToken(data.AnswerOtpChallenge.refreshToken);
                    _cloudStore.setTokenExpirationTime(data.AnswerOtpChallenge.expiresIn);
                    _cloudStore.changeAuthState('SIGNED_IN');
                    if (_cloudStore.userId) {
                        sessionStorage.setItem('userId', _cloudStore.userId)
                    };
                    resolve({ userId: _cloudStore.userId || '' })
                })
                .catch(reject)
        })
    );
};

const signOutMutation = `mutation SignOut($refreshToken:String!, $clientId:String!) {
    SignOut(refreshToken: $refreshToken, clientId:$clientId){
        status
    }
}`;

export const signOut = () => {
    _cloudStore.changeAuthState('SIGNING_OUT');
    return (
        new Promise<void>((resolve, reject) => {
            query({
                query: signOutMutation,
                variables: {
                    refreshToken: _cloudStore.refreshToken,
                    clientId: _cloudStore.awsConfiguration.userPoolClientId,
                },
                authMode: 'AWS_IAM'
            })
                .then(data => {
                    console.log(data);
                    _cloudStore.clearTokensAndUserId();
                    _cloudStore.changeAuthState('SIGNED_OUT');
                    resolve()
                })
                .catch(error => {
                    console.log(error);
                    _cloudStore.clearTokensAndUserId();
                    _cloudStore.changeAuthState('SIGNED_OUT');
                    reject(error)
                })
        })
    );
};

const refreshTokensMutation = `mutation RefreshTokens($refreshToken:String!, $clientId:String!, $userId:String!) {
    RefreshTokens(refreshToken: $refreshToken, clientId:$clientId, userId:$userId){
        accessToken
        expiresIn
        idToken
        tokenType
    }
}`;

export const refreshTokens = () => {
    console.log('REFRESHING TOKENS', new Date().toString());
    return (
        query({
            query: refreshTokensMutation,
            variables: {
                refreshToken: _cloudStore.refreshToken,
                clientId: _cloudStore.awsConfiguration.userPoolClientId,
                userId: _cloudStore.userId
            },
            authMode: 'AWS_IAM'
        })
            .then(data => {
                // console.log('Refreshed:', data);
                _cloudStore.setAccessToken(data.RefreshTokens.accessToken);
                _cloudStore.setIdentityToken(data.RefreshTokens.idToken);
                _cloudStore.setTokenExpirationTime(data.RefreshTokens.expiresIn);
                _cloudStore.changeAuthState('SIGNED_IN');
            })
            .catch(console.log)
    );
};
