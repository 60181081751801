import { Platform } from 'react-native';
import * as reactRouterDom from 'react-router-dom';
import * as reactRouterNative from 'react-router-native';

export const Router = Platform.OS === 'web' ? reactRouterDom.BrowserRouter : reactRouterNative.NativeRouter;

export const Link = Platform.OS === 'web' ? reactRouterDom.Link : reactRouterNative.Link;

export const Route = Platform.OS === 'web' ? reactRouterDom.Route : reactRouterNative.Route;

export const Routes = Platform.OS === 'web' ? reactRouterDom.Routes : reactRouterNative.Routes;

export const useParams = Platform.OS === 'web' ? reactRouterDom.useParams : reactRouterNative.useParams;

export const useNavigate = Platform.OS === 'web' ? reactRouterDom.useNavigate : reactRouterNative.useNavigate;

export const Outlet = Platform.OS === 'web' ? reactRouterDom.Outlet : reactRouterNative.Outlet;

export const useOutletContext = Platform.OS === 'web' ? reactRouterDom.useOutletContext : reactRouterNative.useOutletContext;

export const resolvePath = Platform.OS === 'web' ? reactRouterDom.resolvePath : reactRouterNative.resolvePath;

export const useLocation = Platform.OS === 'web' ? reactRouterDom.useLocation : reactRouterNative.useLocation;