import { useEffect, useContext, Suspense, lazy } from 'react';
import { View, ActivityIndicator } from 'react-native';
import { observer } from 'mobx-react-lite';
import { Route, Router, Routes } from '../ui/Router';
import { pageType, siteContext } from './stores/siteConfig';
import ErrorBoundary from './ErrorBoundary';
import Page from './Page';

export default observer(() => {
    const siteConfig = useContext(siteContext);

    useEffect(() => {
        siteConfig.saturateWebsite();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (siteConfig.saturated && siteConfig.favicon) {
            const favicon = document.createElement('link');
            favicon.rel = 'icon';
            favicon.href = siteConfig.favicon.url;
        } else {
            //do nothing
        };
    }, [siteConfig.favicon, siteConfig.saturated]);

    const NavComponent = lazy(() => import(`./sections/${siteConfig.navType}`));

    const BackgroundComponent = lazy(() => import(`./sections/${siteConfig.backgroundType}`));

    const FooterComponent = lazy(() => import(`./sections/${siteConfig.footerType}`));

    if (siteConfig.saturated) {
        return (
            <View
                style={{ backgroundColor: siteConfig.colorAsRgb(siteConfig.backgroundColor), minHeight: '100vh',  /*maxHeight: '-webkit-fill-available', */ flexGrow: 1 }}
            >
                <Router>
                    <View
                        style={{ width: '100%',  /*minHeight: '100%',*/ flexGrow: 1 }}
                    >
                        <ErrorBoundary>
                            <Suspense fallback={<View />}>
                                {/* 
                        //@ts-ignore */}
                                <View style={{
                                    opacity: 1,
                                    //@ts-ignore
                                    position: 'sticky',
                                    top: 0,
                                    zIndex: 1
                                }}>
                                    <NavComponent
                                        section={{}}
                                    />
                                </View>
                            </Suspense>
                        </ErrorBoundary>
                        <Routes>
                            {siteConfig.pages.map((page: pageType, index: number) =>
                                //@ts-ignore
                                <Route
                                    caseSensitive={false}
                                    element={<Page page={page} />}
                                    index={!page.path}
                                    path={page.path}
                                    key={index}
                                >
                                    {page.subRoutes[0] ?
                                        <Route
                                            caseSensitive={false}
                                            element={<Page page={page.subRoutes[0]} />}
                                        />
                                        : null}
                                    {
                                        page.subRoutes.map((subRoute: pageType, index: number) =>
                                            <Route
                                                caseSensitive={false}
                                                element={<Page page={subRoute} />}
                                                path={subRoute.path}
                                                key={index}
                                            />
                                        )
                                    }
                                </Route>
                            )}
                        </Routes>
                        <View>
                            <ErrorBoundary>
                                <Suspense fallback={<View />}>
                                    <FooterComponent
                                        section={{}}
                                    />
                                </Suspense>
                            </ErrorBoundary>
                        </View>
                        {/* 
                        //@ts-ignore */}
                        {/* <View style={{ position: 'sticky' }}>
                            <ErrorBoundary>
                                <Suspense fallback={<View />}>
                                    <NavComponent
                                        section={{}}
                                    />
                                </Suspense>
                            </ErrorBoundary>
                        </View> */}
                    </View>
                </Router>
            </View>
        );
    } else {
        return (
            <View
                style={{
                    backgroundColor: `rgba(40,44,51,1)`,
                    height: '100vh',
                    minHeight: '100%',
                    width: '100vw',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <ActivityIndicator size={'large'} />
            </View>
        )
    };
});
