
import { observer } from 'mobx-react-lite';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { SiteConfigStore, siteContext } from './stores/siteConfig';
import { CloudStore, cloudContext } from '../ui/AWS';
import Layout from './Layout';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

export default observer(() => {

    // console.log('app  render');

    return (
        <cloudContext.Provider value={CloudStore()}>
            <siteContext.Provider value={SiteConfigStore()}>
                <Elements stripe={stripePromise}>
                    <Layout />
                </Elements>
            </siteContext.Provider>
        </cloudContext.Provider>
    );
});
